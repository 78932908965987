<template src="./phieumuon.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip, BAvatar,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import { PHIEU_MUON_STATUSES } from '@/const/status'
export default {
  name: 'Index',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    VueGoodTable,
    vSelect,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'Số phiếu',
            field: 'code',
            with: '5%',
            sortable: false,
          },
          {
            label: 'Danh sách mượn',
            field: 'listDocuments',
            sortable: false,
          },
          {
            label: 'Ngày mượn',
            field: 'ngayMuon',
            sortable: false,
          },
          {
            label: 'Ngày đến lấy',
            field: 'ngayDenLay',
            sortable: false,
          },
          {
            label: 'Trạng thái',
            field: 'status',
            sortable: false,
          },
          {
            label: '',
            field: 'actice',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
          },
        ],
        rows: [],
      },
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      itemsDisplay: [],
      dataSendForm: {},
      total: 0,
      status: PHIEU_MUON_STATUSES,
    }
  },
  async created() {
    // init data FROM API
    await this.loadItems()
  },
  computed: {
    PHIEU_MUON_STATUSES() {
      return PHIEU_MUON_STATUSES
    },
  },
  watch: {
    // listening change data init
  },
  methods: {
    ...mapActions({
      getListOfBorrowing: 'digitalLibrary/getListOfBorrowing',
    }),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.loadItems()
    },
    async onPerPageChange(params) {
      this.updateParams({ page: 1, perPage: params.currentPerPage })
      await this.loadItems()
    },

    getNameStatus(status) {
      return this.PHIEU_MUON_STATUSES.find(item => item.value == status).text
    },
    // load items is what brings back the rows from server
    async loadItems() {
      this.isLoading = true
      try {
        const dsAnPham = await this.getListOfBorrowing()
        this.table.rows = dsAnPham.data.rows
        this.total = dsAnPham.data.total_record
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
