import { render, staticRenderFns } from "./phieumuon.html?vue&type=template&id=797305e4&scoped=true&external"
import script from "./PhieuMuon.vue?vue&type=script&lang=js"
export * from "./PhieuMuon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797305e4",
  null
  
)

export default component.exports